// import { useEffect  } from 'react';
import { useContext, useEffect } from 'react';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { getAccessToken } from '../../helpers/services/admin.services';
import { useNavigate } from "react-router-dom";
import GlobalStateContext from '../../GlobalStateContext';
import secureLocalStorage from "react-secure-storage";
import toast from 'react-hot-toast';

const LoginPage = () => {
  const navigate = useNavigate();
  const { setRoles, roles } = useContext(GlobalStateContext);
  const sessionDuration =  2 * 60 * 1000; 

  useEffect(() => {
    const accessToken = secureLocalStorage.getItem("accessToken");
    const sessionExpiration = secureLocalStorage.getItem("sessionExpiration");

    if (accessToken && roles) {
      const currentTime = new Date().getTime();
      if (sessionExpiration && currentTime > sessionExpiration) {
        // Session expired
        toast.error("Session expired. Please log in again.");
        secureLocalStorage.removeItem("accessToken");
        secureLocalStorage.removeItem("roles");
        secureLocalStorage.removeItem("sessionExpiration");
        navigate("/login"); // Redirect to login page
      } else {
        // Session valid
        if (roles?.sales_manager || roles.marketing_manager) {
          navigate("/properties");
        } else if (roles?.food_vp) {
          navigate("/food");
        } else {
          navigate("/residents");
        }
      }
    }
  }, [roles, navigate]);

  const handleLoginSuccess = async (credential_response) => {
    try {
      const response = await getAccessToken({
        google_token: credential_response.credential,
      });

      if (response.status === "success") {
        let roles = {};
        if (response?.data?.roles) {
          response.data.roles?.map((role) => (roles[role] = true));
        }
        setRoles({ ...roles });

        const currentTime = new Date().getTime();
        const sessionExpiration = currentTime + sessionDuration; // Set session expiration 5 days from now

        // Store access token, roles, and session expiration
        await secureLocalStorage.setItem("accessToken", response?.data?.token);
        await secureLocalStorage.setItem("roles", roles);
        await secureLocalStorage.setItem("sessionExpiration", sessionExpiration);

        setTimeout(() => {
          if (roles?.sales_manager || roles.marketing_manager) {
            navigate("/properties");
          } else if (roles?.food_vp) {
            navigate("/food");
          } else {
            navigate("/residents");
          }
        }, 1000);
      } else {
        toast.error("You are not authorized");
      }
    } catch (error) {
      console.log("Error while login -- ", error);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: 500 }}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          handleLoginSuccess(credentialResponse);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
        auto_select={false}
      />
    </div>
  );
};

export default LoginPage;
